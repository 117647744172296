import React, { useState, useEffect } from "react";
import "./NoInternetBlocker.css";
import useNetworkStatus from "../../hooks/useNetworkStatus";
import { GiDeadHead } from "react-icons/gi";

const NoInternetBlocker = () => {
  const [dotCount, setDotCount] = useState(1);
  const isOnline = useNetworkStatus();

  useEffect(() => {
    if (!isOnline) {
      setDotCount(1);
      const intervalId = setInterval(() => {
        setDotCount((prevCount) => (prevCount % 10) + 1);
      }, 500); // Adjust the speed of dot animation as needed

      return () => clearInterval(intervalId);
    }
  }, [isOnline]);

  return (
    <>
      {!isOnline && (
        <div className="NoInternetBlocker-background">
          <div className="NoInternetBlocker-container">
            <GiDeadHead className="NoInternetBlocker-icon" />
            <div className="NoInternetBlocker-text">Dead.</div>
            <div className="NoInternetBlocker-text">Like your internet.</div>
            <div className="NoInternetBlocker-text NoInternetBlocker-text-bottom">
              Waiting on connection
              <div>{new Array(dotCount).fill(".").join("")}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NoInternetBlocker;
