// Importing React and necessary hooks for the component to work.
import React, { useRef, useEffect } from 'react';
// Importing a CSS file for styling this specific component.
import './QRCodeScanner.css';
// Importing the Webcam component from the 'react-webcam' library.
import Webcam from 'react-webcam';

// The WebcamCapture component, responsible for capturing webcam footage and detecting QR codes.
const WebcamCapture = ({ onScan, style }) => {
  // Creating a ref to directly interact with the webcam element.
  const webcamRef = useRef(null);

  // Function to capture an image from the webcam and send it to the parent component.
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot(); // Gets the current image from the webcam.
    onScan(imageSrc); // Sends the image source to the parent component's onScan function.
  };

  useEffect(() => {
    // Setting up a timer to capture images from the webcam at intervals of 500ms.
    const timer = setInterval(() => {
      capture(); // Calls the capture function below.
    }, 500);
    // Cleanup function to clear the interval when the component is unmounted or rerendered.
    return () => clearInterval(timer);
  }); // Empty dependency array means this effect runs once on mount and cleanup on unmount.

  // Define the parameters for the webcam feed.
  const videoConstraints = {
    width: window.innerWidth < 500 ? (window.innerWidth * .9) : 500, // Adjusts the width based on the screen size.
    height: window.innerHeight < 1200 ? (window.innerHeight * .50) : 500, // Adjusts the height based on the screen size.
    facingMode: "environment", // Prefers the rear-facing camera if available.
  };

  // The render part of the component, displaying the webcam view.
  return (
    <div>
      <Webcam
        audio={false} // Disables audio capture.
        ref={webcamRef} // Links the ref to the Webcam component for direct manipulation.
        screenshotFormat="image/jpeg" // Sets the format of the captured screenshot.
        videoConstraints={videoConstraints} // Applies the defined video constraints.
        style={{ borderRadius: "10px", overflow: "hidden", marginBottom: "10px" }} // Inline styles for the webcam view.
        onClick={(e) => { e.stopPropagation(); capture(); }} // Stops click events from propagating and captures an image on click.
      />
    </div>
  );
};

export default WebcamCapture; // Exports the component for use in other parts of the application.
