import React, { useState, useEffect } from "react";
import "./Logo.css";
import Image from "../../assets/images/mascot_512x512.png";

const Logo = ({ height, width, style }) => {
  const [logoSize, setLogoSize] = useState({ width, height });
  const [nameFontSize, setNameFontSize] = useState({ fontSize: `124px` });
  const [sloganFontSize, seSloganFontSize] = useState({ fontSize: "22px" });

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 490) {
        setLogoSize({ width: "300px", height: "100px" });
        setNameFontSize({ fontSize: "64px" });
        seSloganFontSize({ fontSize: "0px" });
      } else if (window.innerWidth < 768) {
        setLogoSize({ width: "500px", height: "150px" });
        setNameFontSize({ fontSize: "84px" });
        seSloganFontSize({ fontSize: "20px" });
      } else {
        setLogoSize({ width, height });
        setNameFontSize({ fontSize: "124px" });
        seSloganFontSize({ fontSize: "22px" });
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Call on mount to set initial size

    return () => window.removeEventListener("resize", handleResize);
  }, [height, width]); // Empty array ensures effect runs only on mount and unmount

  return (
    <div
      id="Logo"
      className="Logo-container"
      style={{ ...style, ...logoSize }}
    >
      <img
        className="Logo-mascot"
        src={Image}
        alt="Dither logo - Ending the Dining Debate!"
      />
      <div className="Logo-text-wrapper">
        <div className="Logo-text-dither" style={{ ...nameFontSize }}>Dither</div>
        <h1 className="Logo-text-slogan" style={{ ...sloganFontSize }}>Ending the Dining Debate</h1>
      </div>
    </div>
  );
};

export default Logo;
