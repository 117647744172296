import React, { useEffect, useState } from "react";
import "./ShareModal.css";
import QRCode from "react-qr-code";

import CustomButton from "../CustomButton/CustomButton";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const ShareModal = ({
  session,
  setIsOpen,
  setAlertModalText,
  setAlertModalIsOpen,
  setAlertModalComponents
}) => {
  const [params, setParams] = useState("");

  useEffect(() => {
    if (session) {
      const string = `https://www.dither.mobi/swipe?joinSession=true&sessionId=${session.sessionId}`;
      setParams(string);
    }
  }, [session]);

  useEffect(() => {
    return () => {
      setAlertModalIsOpen(false);
      setAlertModalText(""); };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Dither",
          text: "Come and dither with me!",
          url: `https://www.dither.mobi/swipe?joinSession=true&sessionId=${session.sessionId}`,
        });
      } catch (err) {

      }
    } else {
      setAlertModalText("Sharing not supported by device.");
      setAlertModalComponents((
          <CustomButton label="Copy Link" onClick={() => {
            navigator.clipboard.writeText(`Come dither with me! Join the swiping fun at https://www.dither.mobi/swipe?joinSession=true&sessionId=${session.sessionId}`);
            alert("Link Copied!");
          }} variant="primary" size="med" style={{ textAlign: "center", marginTop: "25px" }} />
      ))
      setAlertModalIsOpen(true);
    }
  };

  return (
    <div className="ShareModal-container">
      <div
        className="ShareModal-backdrop"
        onClick={(isOpen) => setIsOpen(!isOpen)}
      >
        <div
          className="ShareModal-QR-wrapper"
          onClick={(e) => {
            e.stopPropagation();
            handleShare();
          }}
        >
          {session && (
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={params}
              viewBox={`0 0 256 256`}
            />
          )}
        </div>
        {!session && (
          <LoadingSpinner isLoading={true} width={100} height={100} />
        )}
      </div>
    </div>
  );
};

export default ShareModal;
