import React from 'react';
import './StyledButton.css';

const StyledButton = ({ label, IconComponent, onClick }) => {
  return (
    <button className="StyledButton-container" onClick={onClick}>
        <span className='StyledButton-label'>
            {label}
        </span>
        {IconComponent &&
            <span className='StyledButton-IconComponent-wrapper'>
                <IconComponent />
            </span>
        }
    </button>
  );
};

export default StyledButton;
