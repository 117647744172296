import React from "react";
import ErrorFallback from "../ErrorFallback/ErrorFallback";
import { logErrorToServer } from "../../utils/errors";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, reported: false };
    //for error reporting
    const url = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_API_WEBSOCKET_WSS : process.env.REACT_APP_API_WEBSOCKET_WSS;
    this.websocket = new WebSocket(url);
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate an error has occurred and store the error.
    return { hasError: true, error };
  }

  async componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    //logErrorToMyService(error, info.componentStack);
    if (process.env.NODE_ENV !== "development" && !this.state.reported) {
      logErrorToServer({ error, info });
      this.setState(({ ...this.state, reported: true }))
    }
  }

  // Define the resetErrorBoundary function
  resetErrorBoundary = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError) {
      // Pass the error and resetErrorBoundary function to the ErrorFallback component
      return (
        <ErrorFallback
          error={this.state.error}
          resetErrorBoundary={this.resetErrorBoundary}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
