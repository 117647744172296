import React from "react";
import ModalPopup from "../../../components/ModalPopup/ModalPopup";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const LoadingModalPopup = ({ modalText, onClose, isOpen  }) => {
  return (
    <ModalPopup className="SwipeScreen-alert-modal"
      isOpen={isOpen}
      onClose={() => onClose((now) => !now)}
      style={{ width: "300px", height: "200px" }}>
        <div style={{ margin: "25px 25px 0 25px"}}>
            <LoadingSpinner isLoading={true} height={40} width={40} />
        </div>
        <div style={{ margin: "10px 10px 10px 10px", textAlign: "center" }}>
          {modalText}
        </div>
      </ModalPopup>
  );
};

export default LoadingModalPopup;
