import React, { useState, useEffect } from "react";
import "./TutorialOverlay.css";
import ReactGA from 'react-ga4';

import CustomButtom from "../CustomButton/CustomButton";

const TutorialOverlay = ({ steps, toggleClose, endFunction }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [currentElement, setCurrentElement] = useState(null);

  useEffect(() => {
    const currentStep = steps[currentStepIndex];
    const element = document.getElementsByClassName(currentStep.elementId)[0];
    // Clone the original element
    let clone = element?.cloneNode(true);
    if (element) {
      // Adjust the clone's styles to highlight it
      clone.style.position = "absolute";
      clone.style.zIndex = 1000; // Ensure it's above other content
      clone.style.pointerEvents = "none"; // Allow clicks to pass through

      // Position the clone over the original element
      const elementRect = element.getBoundingClientRect();
      clone.style.top = `${elementRect.top + window.scrollY}px`;
      clone.style.left = `${elementRect.left + window.scrollX}px`;
      clone.style.width = `${elementRect.width}px`;
      clone.style.height = `${elementRect.height}px`;

      // Append the clone to the body or a specific container
      document.body.appendChild(clone);
      element.style.display = "none";

      setCurrentElement(element);
      const newPosition = {
        top:
          elementRect.top +
          window.scrollY -
          (currentStep.offset ? currentStep.offset : 110), // Adjust height as needed
        left: elementRect.left + window.scrollX,
      };

      setTooltipPosition(newPosition);

      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.classList.add("tutorial-highlight");
      if (currentStepIndex > 0) {
        const prevStep = steps[currentStepIndex - 1];
        const prevElement = document.getElementsByClassName(
          prevStep.elementId
        )[0];
        if (prevElement) prevElement.classList.remove("tutorial-highlight");
      }
    }
    return () => {
      if (element) {
        element.classList.remove("tutorial-highlight");
        element.style.display = "flex";
        if (clone) {
          clone.remove();
        }
      }
    };
  }, [currentStepIndex, steps]);

  const nextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };

  const prevStep = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  return (
    <div
      className="tutorial-background"
      onClick={() => {
        if (process.env.NODE_ENV !== "development") {
          ReactGA.event({
            category: "TUTORIAL",
            action: "TUTORIAL_CLOSED_EARLY",
          });
        }
        toggleClose((visible) => !visible);
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="tutorial-text"
        style={{
          position: "absolute",
          top:
            currentElement && !steps[currentStepIndex].centered
              ? `${tooltipPosition.top}px`
              : `50vh`,
          left:
            currentElement && !steps[currentStepIndex].centered
              ? `${tooltipPosition.left}px`
              : `50vw`,
          transform:
            currentElement && !steps[currentStepIndex].centered
              ? ""
              : "translate(-50%,-50%)",
        }}
      >
        {steps[currentStepIndex].children}
        <div className="tutorial-button-wrapper">
          <CustomButtom
            label="Prev"
            onClick={prevStep}
            variant="primary"
            size="small"
            disabled={currentStepIndex === 0}
          />
          <CustomButtom
            label={currentStepIndex === steps.length - 1 ? "Exit" : "Next"}
            onClick={
              currentStepIndex === steps.length - 1
                ? endFunction
                  ? endFunction
                  : () => toggleClose((visible) => !visible)
                : nextStep
            }
            variant="primary"
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

export default TutorialOverlay;
