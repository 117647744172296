import { useState, useEffect } from 'react';

const useNetworkStatus = () => {
  // State to hold the online status
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const goOnline = () => setIsOnline(true);
    const goOffline = () => setIsOnline(false);

    // Add event listeners to detect online and offline status
    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    // Clean up event listeners
    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  return isOnline;
};

export default useNetworkStatus;
