import ReactGA from "react-ga4";
import { logErrorToServer } from "./errors";
import { isValidJsonString } from "./json";

export const getLocation = () => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          resolve(location);
          if (
            process.env.NODE_ENV === "development" &&
            process.env.REACT_APP_DEV_DUMP_LOCATION === "enabled"
          ) {
            console.log(
              `DEV::LOCATION: ${JSON.stringify(location)}`
            );
          }
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              resolve(false);
              break;
            case error.POSITION_UNAVAILABLE:
              resolve(false); //Location information is unavailable.
              break;
            case error.TIMEOUT:
              resolve(false); //The request to get user location timed out.
              break;
            case error.UNKNOWN_ERROR:
              resolve(false); //An unknown error occurred.
              break;
            default:
              logErrorToServer({ error, info: {} });
              resolve(false);
          }
        }
      );
    } else {
      reject("Geolocation API not supported");
    }
  });
};

export const getParsedLocation = (location) => {
  const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
  try {
    let parsedLocation;
    //handle json objects or strings
    if (isValidJsonString(location)) parsedLocation = JSON.parse(location);
    else parsedLocation = location;

    if (
      parsedLocation.hasOwnProperty("latitude") &&
      parsedLocation.hasOwnProperty("longitude")
    ) {
      return `${parsedLocation.latitude},${parsedLocation.longitude}`;
    }
  } catch (e) {
    console.log(e)
    return false;
  }
  if (zipCodeRegex.test(location)) {
    return location;
  }
  return false;
};

export const updateSessionLocation = (setLocation) => {
  return new Promise((resolve, reject) => {
    return getLocation()
      .then((coords) => {
        if (process.env.NODE_ENV !== "development") {
          ReactGA.event({
            category: "USER_LOCATION",
            action: "UPDATED_LOCATION",
            label: JSON.stringify(coords),
          });
        }
        setLocation(coords); //update location state
        resolve(coords);
      })
      .catch((e) => {
        const error = new Error(
          "Error 420: Failed to get location. Please try again."
        );
        reject(error);
        throw error;
      });
  });
};

export const getUseableLocation = (location) => {
  try {
    if (checkIfZipcode(location)) return location;
    const [latitude, longitude] = location.split(",");
    return {
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
    };
  } catch (e) {
    return false;
  }
};

export const checkIfZipcode = (location) => {
  const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return zipCodeRegex.test(location);
};

export const calculateDistance = (startCoords, finishCoords) => {
  const R = 6371e3; // Radius of the Earth in meters
  const phi1 = (startCoords.latitude * Math.PI) / 180; // Convert degrees to radians
  const phi2 = (finishCoords.latitude * Math.PI) / 180;
  const deltaPhi =
    ((finishCoords.latitude - startCoords.latitude) * Math.PI) / 180;
  const deltaLambda =
    ((finishCoords.longitude - startCoords.longitude) * Math.PI) / 180;

  const a =
    Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
    Math.cos(phi1) *
      Math.cos(phi2) *
      Math.sin(deltaLambda / 2) *
      Math.sin(deltaLambda / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distanceInMeters = R * c; // Distance in meters
  return distanceInMeters / 1609.34; // Convert distance to miles
};
