import React from "react";
import ModalPopup from "../../../components/ModalPopup/ModalPopup";
import CustomButton from "../../../components/CustomButton/CustomButton";

const AlertModalPopup = ({
  alertModalText,
  setAlertModalIsOpen,
  alertModalIsOpen,
  AlertModalComponents,
}) => {
  const parsedText = alertModalText.split("<br>");
  return (
    <ModalPopup
      className="SwipeScreen-alert-modal"
      isOpen={alertModalIsOpen}
      onClose={() => setAlertModalIsOpen((now) => !now)}
      style={{ width: "300px", height: "200px" }}
    >
      <div className="SwipeScreen-alert-modal-text">
        {Array.isArray(parsedText) &&
          parsedText.map((s) => <div key={s} style={{ marginBottom: "5px" }}>{s}</div>)}

        {!Array.isArray(parsedText) && <div>{alertModalText}</div>}

        {AlertModalComponents && AlertModalComponents}
      </div>
      <CustomButton
        label="Close"
        className="SwipeScreen-modal-popup-button"
        variant="primary"
        size="med"
        fontWeight={700}
        fontSize="20px"
        style={{ width: "100%", textAlign: "center" }}
        onClick={() => setAlertModalIsOpen((now) => !now)}
      />
    </ModalPopup>
  );
};

export default AlertModalPopup;
