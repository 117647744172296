export const cuisines = [
    'African',
    'American',
    'Argentine',
    'Brazilian',
    'British',
    'Cajun & Creole',
    'Caribbean',
    'Chinese',
    'Cuban',
    'Filipino',
    'French',
    'German',
    'Greek',
    'Hawaiian',
    'Indian',
    'Italian',
    'Japanese',
    'Korean',
    'Mediterranean',
    'Mexican',
    'Middle Eastern',
    'Peruvian',
    'Portuguese',
    'Russian',
    'Southwestern',
    'Southern',
    'Spanish',
    'Thai',
    'Vietnamese'
  ];
