const noSwipeOneLiners = [
    "Hungry? Just take a wild guess, will ya?",
    "All these choices, yet here you are!",
    "Too many restaurants, too little time!",
    "Decision paralysis? We're here to help... sort of.",
    "If you don't pick soon, your stomach will!",
    "Swipe left, swipe right, just don’t swipe away your appetite!",
    "Indecision is the thief of meal time!",
    "You must be really hungry to see this message!",
    "Don’t starve over a swipe!",
    "Remember, the best restaurant is the one you finally choose!",
    "Still undecided? Even a coin flip is faster!",
    "Swipe or starve – your choice!",
    "You've seen them all, now make the call!",
    "This isn't a dating app, just pick a place!",
    "Too many swipes, not enough bites!",
    "Congratulations, you've swiped more than a cat at a yarn store!"
];

const loadPlacesOneLiners = [
    "Fetching tasty spots...",
    "Uncovering culinary gems...",
    "Loading flavor destinations...",
    "Finding your next meal...",
    "Seeking out delicious eats...",
    "Hunting for hunger fixes...",
    "Scouting for sustenance...",
    "Discovering dining delights...",
    "Spotlighting savory selections...",
    "Rounding up great eats...",
    "Navigating the food map...",
    "Charting a course for taste...",
    "Zeroing in on flavor...",
    "Picking out palate pleasers...",
    "Cruising through cuisines...",
  ];


export const getNoSwipesOneliner = () => {
    const randomIndex = Math.floor(Math.random() * noSwipeOneLiners.length);
    return noSwipeOneLiners[randomIndex];
}

export const getLoadPlacesOneliner = () => {
    const randomIndex = Math.floor(Math.random() * loadPlacesOneLiners.length);
    return loadPlacesOneLiners[randomIndex];
}
