import React from 'react';
import './ToolTip.css';

const ToolTip = ({ text, children, style, bottom }) => {
  return (
    <div className="tooltip">
      <span style={style} className={`tooltip-text ${bottom ? 'bottom' : ''}`}>{text}</span>
      {children}
    </div>
  );
};

export default ToolTip;
