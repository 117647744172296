import React from 'react';
import './CustomTextArea.css';

const CustomInput = ({ label, value, onChange, style }) => {

  return (
    <div className='input-field-wrapper' style={style}>
      <label className='input-label'>{label}</label>
      <textarea
        value={value}
        onChange={e => onChange(e.target.value)}
        className="input-field"
        style={{ height: "100px" }}
      />
    </div>
  );
}

export default CustomInput;
