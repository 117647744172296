import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import "./MatchFound.css";
import { useSession } from "../../contexts/SessionContext";
import { useNavigate } from "react-router-dom";
import { apiResetSwipes } from "../../utils/apiCalls";
import { startResetSwipesListener } from "../../utils/listeners";

import matchMadeText from "../../assets/images/match_found.png";
import CustomButton from "../CustomButton/CustomButton";

const MatchFound = ({ match, setIsLoadingModalOpen, setLoadingModalText }) => {
  const navigate = useNavigate();
  const {
    session,
    websocket,
    generateResponseId,
    checkResponseId,
    getDeviceId,
  } = useSession();
  const [imageLoaded, setImageLoaded] = useState(false);

  const resetSwipes = () => {
    setLoadingModalText("Resettings swipes...");
    setIsLoadingModalOpen(true);
    const responseId = generateResponseId();
    const deviceId = getDeviceId();
    const sessionId = session.sessionId;

    startResetSwipesListener({
      checkResponseId,
      responseId,
      websocket,
    })
      .then((data) => {
        if (data.success === true) {
          navigate(0);
        } else {
          throw new Error("Error resetting swipes!");
        }
      })
      .catch((error) => {
        throw new Error("Error resetting swipes!");
      });

    apiResetSwipes({
      websocket,
      responseId,
      deviceId,
      sessionId,
    });
  };

  useEffect(() => {
    const image = new Image();
    image.src = match.photo;
    image.onload = () => {
      setImageLoaded(true);
    };
  }, [match.photo]);

  return (
    <div className="MatchFound-container">
      <img
        src={matchMadeText}
        alt="You've got a match"
        className="MatchFound-text-image"
      />
      {imageLoaded && (
        <div className="MatchFound-animation-container">
          <img
            src={match.photo}
            alt="Match"
            className="MatchFound-image-swoop-zoom"
          />
          <div className="MatchFound-buttons-container">
            <CustomButton
              label="Take Me There!"
              className="MatchFound-go-image"
              variant="primary"
              size="med"
              fontWeight={900}
              fontSize="24px"
              style={{ width: "300px" }}
              onClick={() => {
                if (process.env.NODE_ENV !== "development") {
                  ReactGA.event({
                    category: "MATCHFOUND",
                    action: "MATCHFOUND_NAVIGATION_OPENED",
                    label: JSON.stringify(match),
                  });
                }
                window.location.href = `geo:0,0?q=${encodeURIComponent(
                  match.formatted_address
                )}`;
              }}
            />
            <CustomButton
              label="Swipe Again"
              className="MatchFound-restart-dither"
              variant="primary"
              size="med"
              fontWeight={900}
              fontSize="24px"
              style={{ width: "300px", marginTop: "25px" }}
              onClick={() => {
                if (process.env.NODE_ENV !== "development") {
                  ReactGA.event({
                    category: "MATCHFOUND",
                    action: "MATCHFOUND_RESET_SWIPES",
                    label: JSON.stringify(match),
                  });
                }
                resetSwipes();
              }}
            />
            <CustomButton
              label="Check out the Menu"
              className="MatchFound-restart-dither"
              variant="primary"
              size="med"
              fontWeight={900}
              fontSize="24px"
              style={{ width: "300px", marginTop: "25px" }}
              onClick={() => {
                if (process.env.NODE_ENV !== "development") {
                  ReactGA.event({
                    category: "MATCHFOUND",
                    action: "MATCHFOUND_MORE_INFO_OPENED",
                    label: JSON.stringify(match),
                  });
                }
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    match.formatted_address
                  )}&query_place_id=${match.place_id}`,
                  "_blank"
                );
              }}
            />
          </div>
          <div className="MatchFound-Card-Info">
            <h2>{match.name}</h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default MatchFound;
