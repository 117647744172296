import React from 'react';
import './CustomSlider.css';

const CustomSlider = ({ id='', label, min=0, max=100, onChange, units, value }) => {

  const handleSliderChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="CustomSlider-container">
        <label className='CustomSlider-label' htmlFor={id}>{label}</label>
        <input
            id={id}
            type="range"
            min={min}
            max={max}
            value={value}
            onChange={handleSliderChange}
            className="CustomSlider-slider"
        />
        <span className="CustomSlider-value">{value} {units}</span>
    </div>
  );
};

export default CustomSlider;
