import React from 'react';
import { FaStar, FaStarHalf } from 'react-icons/fa';
import './RatingStars.css';

const RatingStars = ({ rating }) => {
    if (!rating) return null;

    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;

    return (
        <div className="RatingStars-stars">
        {[...Array(fullStars)].map((_, i) => (
            <FaStar key={i} className='RatingStars-star' />
        ))}
        {halfStar && <FaStarHalf className='RatingStars-star' />}
        </div>
    );
};

export default RatingStars;
