import React from 'react';
import './ModalPopup.css';
import { LiaWindowCloseSolid } from "react-icons/lia";

const ModalPopup = ({ isOpen, onClose, children, allowExit = true }) => {
  if (!isOpen) { //if modal is NOT set as open through state prop, return nothing
    return null;
  }

  return (
    <div className="ModalPopup-backdrop" onClick={allowExit ? onClose : null}>
      <div className="ModalPopup-content" onClick={e => e.stopPropagation() /* Prevent the modal from closing when the modal itself is clicked on */}>
        {children}
        { allowExit &&
          <LiaWindowCloseSolid className="ModalPopup-close-button" onClick={onClose} />
        }
      </div>
    </div>
  );
};

export default ModalPopup;
