import React from "react";
import "./LoadingSpinner.css";

const LoadingSpinner = ({ isLoading, height, width }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="spinner-container">
      <div
        className="spinner"
        style={{
          width: width ? width : "50px",
          height: height ? height : "50px",
        }}
      >
        <img
          className="spinner-icon"
          src="/acorn.png"
          alt="Loading..."
          style={{
            width: width ? width : "50px",
            height: height ? height : "50px",
          }}
        />
      </div>
    </div>
  );
};

export default LoadingSpinner;
