export const combinePlacesUniquely = (newPlaces, oldPlaces) => {
  // If the inputs are Sets, convert them to Arrays
  const arr1 = Array.isArray(newPlaces) ? newPlaces : [...newPlaces];
  const arr2 = Array.isArray(oldPlaces) ? oldPlaces : [...oldPlaces];

  const combinedArray = arr1.concat(arr2);
  const uniqueSignatures = new Set();
  const uniqueObjects = [];

  combinedArray.forEach((obj) => {
    const objString = JSON.stringify(obj);
    if (!uniqueSignatures.has(objString)) {
      uniqueSignatures.add(objString);
      uniqueObjects.push(obj);
    }
  });

  return uniqueObjects;
};
