import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./LandingScreen.css";
import { TbLogin2 } from "react-icons/tb";
import { TbHelpHexagon } from "react-icons/tb";
import { BsUpcScan } from "react-icons/bs";
import { useSession } from "../../contexts/SessionContext";
import ReactGA from "react-ga4";
import {
  startGetSessionListener,
  startSendInquiryListener,
} from "../../utils/listeners";
import { isValidEmail } from "../../utils/inputValidators";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { apiSendInquiry } from "../../utils/apiCalls";

import Logo from "../../components/Logo/Logo";
import StyledButton from "../../components/StyledButton/StyledButton";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import QRCodeScanner from "../../components/QRCodeScanner/QRCodeScanner";
import TutorialOverlay from "../../components/TutorialOverlay/TutorialOverlay";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomTextArea from "../../components/CustomTextArea/CustomTextArea";
import ModalPopup from "../../components/ModalPopup/ModalPopup";
import FullscreenToggleButton from "../../components/FullscreenToggleButton/FullscreenToggleButton";

const params = new URLSearchParams(window.location.search);
const sticker = params.get("sticker");

const LandingScreen = () => {
  const navigate = useNavigate();
  const {
    session,
    setSession,
    user,
    setUser,
    websocket,
    startSession,
    getDeviceId,
    generateResponseId,
    getLocalSession,
    setLocalConfig,
    getLocalConfig,
    checkResponseId,
  } = useSession();
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [qrScannerVisible, SetQrScannerVisible] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [installOpen, setInstallOpen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [fullscreenOpen, setFullscreenOpen] = useState(false);
  const [contactFormOpen, setContactFormOpen] = useState(false);
  const [contactFormName, setContactFormName] = useState("");
  const [contactFormEmail, setContactFormEmail] = useState("");
  const [contactFormMessage, setContactFormMessage] = useState("");
  const [contactFormLoading, setContactFormLoading] = useState(false);
  const [contactFormSuccess, setContactFormSuccess] = useState(false);
  const [contactFormError, setContactFormError] = useState("");

  //handle fullscreen
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screens
  useEffect(() => {
    const fullscreenPreference = localStorage.getItem("fullscreenPreference");
    if (fullscreenPreference !== "small") {
      setFullscreenOpen(true); // Show the popup if the user hasn't selected "Go Small!"
    }
  }, [getLocalConfig]);

  //check if visiting via a sticker from promotion
  useEffect(() => {
    if (sticker) {
      if (process.env.NODE_ENV !== "development") {
        ReactGA.event({
          category: "PROMOTIONS",
          action: "STICKER_SCAN",
        });
      }
    }
  }, []);

  //force continue button to show when user navigates back from the swiping page
  useEffect(() => {}, [session, user]);

  //check if users first time to visit
  useEffect(() => {
    const firstVisit = getLocalConfig("firstVisit");
    if (firstVisit && firstVisit === "false") {
      //truthy if it has a value, because the value has been previously set
      if (process.env.NODE_ENV !== "development") {
        ReactGA.event({
          category: "LANDING",
          action: "LANDING_USER_RETURNED",
        });
      }
      //check if user has already been asked to install already
      const installInquiry = getLocalConfig("installInquiry");
      if (!installInquiry) {
        //if the user hasn't been asked yet
        setLocalConfig("installInquiry", "true"); //set the state for future visits
        window.addEventListener("beforeinstallprompt", (e) => {
          //trigger prompts
          // Prevent the mini-infobar from appearing on mobile
          e.preventDefault();
          // Stash the event so it can be triggered later.
          setDeferredPrompt(e);
          // Update UI to notify the user they can install the PW
          setInstallOpen(true);
        });
      } else {
        //10% chance the user will be asked to isntall on subsequent visits
        if (Math.random() < 0.1) {
          window.addEventListener("beforeinstallprompt", (e) => {
            //trigger prompts
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later.
            setDeferredPrompt(e);
            // Update UI to notify the user they can install the PW
            setInstallOpen(true);
          });
        }
      }
    } else {
      setLocalConfig("firstVisit", "false"); //set it to false for next visit
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goSwipe = (sessionId) => {
    if (!sessionId) {
      if (process.env.NODE_ENV !== "development") {
        ReactGA.event({
          category: "SESSIONS",
          action: "START_SESSION",
        });
      }
      startSession();
    } else {
      //reset and create new deviceId/session if no sessionId is provided
      if (process.env.NODE_ENV !== "development") {
        ReactGA.event({
          category: "SESSIONS",
          action: "CONTINUE_SESSION",
        });
      }
    }
    navigate(`/swipe${sessionId ? "?sessionId=" + sessionId : ""}`);
  };

  useEffect(() => {
    const localSession = getLocalSession();
    const deviceId = getDeviceId();
    const responseId = generateResponseId();

    if (websocket) {
      //if instantiated, attach event listeners for updating session information
      let openHandler = (event) => {
        //listener for when websocket connection opens
        websocket.sendMessage({
          action: "getSession",
          responseId,
          deviceId,
          sessionId: localSession?.sessionId,
        });
        setShowLoadingSpinner(true); //display loading spinner while we wait on response
        startGetSessionListener({
          checkResponseId,
          responseId,
          setUser,
          setLocalConfig,
          setSession,
          websocket,
        }).then((data) => {
          setShowLoadingSpinner(false);
        });
      };
      websocket.addEventListener("open", openHandler);
      //perform cleanup on socket error when attempting to connect
      const cleanUpListener = () => {
        websocket.removeEventListener("open", openHandler);
        websocket.removeEventListener("close", cleanUpListener);
      };
      websocket.addEventListener("error", cleanUpListener);
      /**
      setTimeout(
        () => websocket.removeEventListener("open", openHandler),
        30000
      ); //time out the listener
      */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocket?.socket]);

  return (
    <div className="LandingScreen-container">
      <HelmetProvider>
        <Helmet>
          <title>Dither - Ending the Dining Debate!</title>
          <meta
            name="description"
            content="Dither helps you quickly decide where to eat by swiping through local restaurants. Say goodbye to the dining debate and hello to your next meal."
          />
          <meta
            name="keywords"
            content="decision making app for eating, restaurant picker app, dinner decision app, where to eat app"
          />
          <script type="application/ld+json">
            {`
            {
              "@context": "http://schema.org",
              "@type": "SoftwareApplication",
              "name": "Dither",
              "applicationCategory": "Utility",
              "operatingSystem": "ANY",
              "description": "Dither helps multiple people quickly decide where to eat by swiping through local restaurants."
            }
          `}
          </script>
          <meta
            property="og:title"
            content="Dither - Ending the Dining Debate!"
          />
          <meta
            property="og:description"
            content="Join Dither and never worry about where to eat again. Swipe, match, and eat!"
          />
          <meta
            property="og:image"
            content="https://www.dither.mobi/og_image_1200x630.png"
          />
          <meta property="og:url" content="https://www.dither.mobi" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:image"
            content="https://www.dither.mobi/twitter_image_800x418.jpg"
          />
        </Helmet>
      </HelmetProvider>
      <Logo
        className="LandingScreen-Logo"
        height={200}
        width={500}
        style={{
          position: "absolute",
          transform: "translateX(-50%)",
          top: "25px",
          left: "50vw",
        }}
      />
      <div
        className="LandingScreen-LoadingSpinner-container"
        aria-live="polite"
      >
        <LoadingSpinner isLoading={showLoadingSpinner} />
      </div>
      <div className="LandingScreen-button-container">
        <div className="start-dithering-button">
          <StyledButton
            label="Start Dithering"
            onClick={() => goSwipe()}
            aria-label="Begin your food discovery journey with Dither"
          />
        </div>
        {session && user && (
          <div className="continue-dithering-button">
            <StyledButton
              label="Continue"
              IconComponent={TbLogin2}
              onClick={() => goSwipe(session?.sessionId)} //Continue session by providing session ID to swipe screen
            />
          </div>
        )}
      </div>
      <div className="LandingScreen-icon-container">
        <TbHelpHexagon
          className="LandingScreen-tutorial-icon"
          onClick={() => setContactFormOpen((visible) => !visible)}
        />
        <FullscreenToggleButton />
        <BsUpcScan
          className="LandingScreen-barcode-icon QRScanner-button"
          offset={150}
          onClick={() => SetQrScannerVisible((visible) => !visible)}
        />
      </div>
      {qrScannerVisible && (
        <div className="LandingScreen-QRScanner-container">
          <QRCodeScanner onClose={SetQrScannerVisible} />
        </div>
      )}
      {showTutorial && (
        <TutorialOverlay
          toggleClose={setShowTutorial}
          steps={[
            {
              elementId: "centered",
              centered: true,
              children: (
                <div>
                  <h2 style={{ margin: "10px 0", whiteSpace: "nowrap" }}>
                    What is Dither?
                  </h2>
                  <div style={{ width: "300px" }}>
                    Dither is a tool to help you and others find a place to eat
                    the easy way. No more indecision, regret or wondering if you
                    picked the right spot. Just swipe and match, it's that easy.
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    Keep going to learn more...
                  </div>
                </div>
              ),
            },
            {
              elementId: "start-dithering-button",
              children: (
                <div>
                  Right here is where you wanna go if you and someone else can't
                  pick a place to eat.
                </div>
              ),
            },
            {
              elementId: "continue-dithering-button",
              offset: 140,
              children: (
                <div>
                  If you've done this kinda thing before, then the "Continue"
                  button may pop up to let you keep on swiping.
                </div>
              ),
            },
            {
              elementId: "QRScanner-button",
              centered: true,
              children: (
                <div>
                  If someone you know is sharing a dither barcode with you,
                  click the scanner button to scan it and join their session.
                </div>
              ),
            },
            {
              elementId: "centered",
              centered: true,
              children: (
                <div>
                  <h2 style={{ margin: "10px 0", whiteSpace: "nowrap" }}>
                    That's it for now!
                  </h2>
                  <div>
                    <div style={{ margin: "0 0 10px 0" }}>
                      Click the exit button now and start dithering!{" "}
                    </div>
                    <div>
                      Your tutorial will continue once you start a new dithering
                      session...
                    </div>
                  </div>
                </div>
              ),
            },
          ]}
          endFunction={() => {
            if (process.env.NODE_ENV !== "development") {
              ReactGA.event({
                category: "TUTORIAL",
                action: "LANDING_TUTORIAL_COMPLETED",
              });
            }
            setShowTutorial((visible) => !visible);
            setLocalConfig("tutorialActive", true); //update variable so that swipe page knows to show tutorial on load
          }}
        />
      )}
      <ModalPopup
        isOpen={contactFormOpen}
        onClose={() => {
          setContactFormOpen((v) => !v);
        }}
      >
        {!contactFormLoading && !contactFormSuccess && (
          <>
            <div style={{ textAlign: "center", margin: "15px 0 15px 0" }}>
              <div>Need to drop us a line?</div>
              <div>Type your message below and hit submit</div>
            </div>
            <CustomInput
              label="Name"
              style={{ marginBottom: "10px" }}
              value={contactFormName}
              onChange={setContactFormName}
              inputStyle={{ textAlign: "center" }}
            />
            <CustomInput
              label="E-Mail"
              style={{ marginBottom: "10px" }}
              value={contactFormEmail}
              onChange={setContactFormEmail}
              inputStyle={{ textAlign: "center" }}
              validators={[isValidEmail]}
            />
            <CustomTextArea
              label="Message"
              value={contactFormMessage}
              onChange={setContactFormMessage}
            />
            <div style={{ textAlign: "center", color: "red" }}>
              {contactFormError}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <CustomButton
                label="Submit"
                onClick={() => {
                  if (process.env.NODE_ENV !== "development") {
                    ReactGA.event({
                      category: "INQUIRIES",
                      action: "INQUIRY_SENT",
                    });
                  }
                  setContactFormError("");
                  const responseId = generateResponseId();
                  const deviceId = getDeviceId();
                  //replace form with loading spinner
                  setContactFormLoading(true);
                  //call to api
                  startSendInquiryListener({
                    checkResponseId,
                    responseId,
                    websocket,
                  })
                    .then((data) => {
                      if (data) {
                        if (data.success === "true") {
                          //replace spinner with success message and close button
                          setContactFormLoading(false);
                          setContactFormSuccess(true);
                          return;
                        }
                      }
                      setContactFormError("Failed to send message.");
                      setContactFormLoading(false);
                      setContactFormSuccess(false);
                    })
                    .catch((error) => {
                      setContactFormLoading(false);
                      setContactFormSuccess(false);
                    });

                  apiSendInquiry({
                    websocket,
                    responseId,
                    deviceId,
                    name: contactFormName,
                    email: contactFormEmail,
                    message: contactFormMessage,
                  });
                }}
                variant="primary"
                size="med"
              />
            </div>
          </>
        )}
        {contactFormLoading && (
          <div style={{ margin: "25px 0 0 0" }}>
            <LoadingSpinner isLoading={contactFormLoading} />
            <div style={{ margin: "15px 0 15px 0" }}>Submitting message...</div>
          </div>
        )}
        {!contactFormLoading && contactFormSuccess && (
          <div style={{ textAlign: "center", margin: "30px 0 15px 0" }}>
            <h4 style={{ margin: "0 0 15px 0" }}>
              Thanks! We like hearing your thoughts!
            </h4>
            <div style={{ marginBottom: "10px" }}>
              Your message has been received!
            </div>
            <div>
              We'll be sure to reach back out when the opportunity arises.
            </div>
          </div>
        )}
        <div
          style={{
            margin: "25px 0 10px 0",
            padding: "25px 0 10px 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderTop: "1px solid var(--primary-color)",
          }}
        >
          <h3 style={{ textAlign: "center", marginBottom: "15px" }}>
            Confused?
          </h3>
          <CustomButton
            label="View Tutorial"
            onClick={() => {
              if (process.env.NODE_ENV !== "development") {
                ReactGA.event({
                  category: "TUTORIAL",
                  action: "LANDING_TUTORIAL_STARTED",
                });
              }
              //set contactForm to closed
              setContactFormOpen(false);
              //set tutorial to open
              setShowTutorial(true);
            }}
            variant="primary"
            size="med"
          />
          <div
            style={{
              position: "absolute",
              bottom: "5px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "flex-end",
              width: "100%",
              marginTop: "15px",
            }}
          >
            <a href="./terms-of-use.html">Terms of use</a> |{" "}
            <a href="./privacy-policy.html">Privacy Policy</a>
          </div>
        </div>
      </ModalPopup>
      {(isMobile && document.fullscreenElement === null) && (
        <ModalPopup
          isOpen={fullscreenOpen}
          onClose={() => setFullscreenOpen((visible) => !visible)}
        >
          <div className="App-install-prompt-container">
            <div
              style={{
                textAlign: "center",
                marginBottom: "15px",
                fontWeight: "bold",
              }}
            >
              This app works best in full screen on mobile
            </div>
            <div className="App-install-button-container">
              <CustomButton
                label="Go Big!"
                variant="primary"
                size="med"
                onClick={() => {
                  document.documentElement
                    .requestFullscreen()
                    .catch(console.error);
                  setFullscreenOpen(false);
                }}
              />
              <CustomButton
                label="Go Small!"
                variant="primary"
                size="med"
                onClick={() => {
                  setFullscreenOpen(false);
                  localStorage.setItem("fullscreenPreference", "small"); // Save the preference
                }}
              />
            </div>
          </div>
        </ModalPopup>
      )}
    </div>
  );
};

export default LandingScreen;
