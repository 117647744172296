import React from "react";
import ReactGA from "react-ga4";
import { TbHelpHexagon } from "react-icons/tb";
import { useSession } from "../../../contexts/SessionContext";
import { getLocation } from "../../../utils/location";
import { getRandomPlace } from "../../../utils/locationsList";

import ModalPopup from "../../../components/ModalPopup/ModalPopup";
import CustomButton from "../../../components/CustomButton/CustomButton";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import ToolTip from "../../../components/ToolTip/ToolTip";

const LocationRequestModalPopup = ({
  locationModalIsOpen,
  setLocationModalIsOpen,
  locationModalSpinnerSpin,
  setLocationModalSpinnerSpin,
  setAlertModalText,
  setAlertModalIsOpen,
}) => {
  const { setLocalConfig, setLocation } = useSession();
  return (
    <ModalPopup
      allowExit={false}
      isOpen={locationModalIsOpen}
      onClose={() => setLocationModalIsOpen((now) => !now)}
      style={{ width: "300px", height: "200px" }}
    >
      <div className="SwipeScreen-location-modal-container">
        <h2 className="SwipeScreen-location-modal-popup-header">
          Location Request
        </h2>
        <div className="SwipeScreen-location-modal-popup-text">
          This app is designed to be location based.
        </div>
        {locationModalSpinnerSpin && (
          <div style={{ padding: "10px 0px" }}>
            <LoadingSpinner isLoading={locationModalSpinnerSpin} />
          </div>
        )}
        {!locationModalSpinnerSpin && (
          <ToolTip
            style={{
              width: "200px",
            }}
            text={`We do not store any personal info, only information on how the application is used.`}
          >
            <TbHelpHexagon className="SwipeScreen-help-icon" />
          </ToolTip>
        )}
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <CustomButton
            label="Enable Location"
            className="SwipeScreen-modal-popup-button"
            variant="primary"
            size="med"
            fontWeight={700}
            fontSize="20px"
            style={{ width: "100%", textAlign: "center" }}
            onClick={() => {
              setLocationModalSpinnerSpin(true);
              getLocation()
                .then((location) => {
                  if (location) {
                    setLocationModalIsOpen(false);
                    setLocationModalSpinnerSpin(false);
                    setLocalConfig("location_accesss", "true");
                    setLocation(location);
                    if (process.env.NODE_ENV !== "development") {
                      ReactGA.event({
                        category: "USER_LOCATION",
                        action: "ENABLED_LOCATION",
                        label: JSON.stringify(location),
                      });
                    }
                  } else {
                    setLocationModalIsOpen(false);
                    setLocationModalSpinnerSpin(false);
                    setLocalConfig("location_accesss", "false");
                    setLocalConfig("location_reason", "denied");
                    setAlertModalText(
                      "Location access not allowed. You may change it in settings later."
                    );
                    setAlertModalIsOpen(true);
                    setLocation(getRandomPlace());
                    if (process.env.NODE_ENV !== "development") {
                      ReactGA.event({
                        category: "USER_LOCATION",
                        action: "DENIED_LOCATION",
                      });
                    }
                  }
                })
                .catch((error) => {
                  setLocalConfig("location_accesss", false);
                  setLocalConfig("location_reason", "error");
                  setLocationModalIsOpen(false);
                  setLocationModalSpinnerSpin(false);
                  setAlertModalText(
                    "Error getting location. <br>You may try again in the settings."
                  );
                  setAlertModalIsOpen(true);
                  setLocation(getRandomPlace());
                  if (process.env.NODE_ENV === "development") {
                    ReactGA.event({
                      category: "USER_LOCATION",
                      action: "ERROR_LOCATION",
                      label: error,
                    });
                  }
                });
            }}
          />
          <CustomButton
            label="Cancel"
            className="SwipeScreen-modal-popup-button"
            variant="primary"
            size="med"
            fontWeight={700}
            fontSize="20px"
            style={{ width: "100%", textAlign: "center" }}
            onClick={() => {
              setLocationModalIsOpen(false);
              setLocationModalSpinnerSpin(false);
              setLocalConfig("location_accesss", "false");
              setLocalConfig("location_reason", "denied");
              setLocation(getRandomPlace());
              setAlertModalText(
                `Location access not allowed. <br> You may change it in settings later.`
              );
              setAlertModalIsOpen(true);
              if (process.env.NODE_ENV !== "development") {
                ReactGA.event({
                  category: "USER_LOCATION",
                  action: "DENIED_LOCATION",
                });
              }
            }}
          />
        </div>
      </div>
    </ModalPopup>
  );
};

export default LocationRequestModalPopup;
