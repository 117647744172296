const isLongEnough = (string, count) => string.length >= (count || 0) ? '' : `Minimium ${count} Characters`;
const isNotEmpty = (string) => string.length !== 0 ? '' : `Must not be empty`;
const hasNumber = (string) => /\d/.test(string) ? '' : 'Must contain a number';
const hasCapitalLetter = (string) => /[A-Z]/.test(string) ? '' : 'Must contain a capital letter';
const isValidEmail = (string) => /\S+@\S+\.\S+/.test(string) ? '' : 'Invalid email';
const matchesPassword = (string, password) => (string === password) ? '' : 'Passwords do not match';
const validateLocationInput = (string) => {
    const zipCodePattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
    const coordinatesPattern = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
    return (!zipCodePattern.test(string) && !coordinatesPattern.test(string)) ? 'Must be coordinates or zip code' : '';
}

const isValidPassword = (string) => {
    if (hasNumber(string)) return hasNumber(string);
    if (hasCapitalLetter(string)) return hasCapitalLetter(string);
    if (isLongEnough(string, 6)) return isLongEnough(string, 6);
    return '';
}

const isValidUsername = (string) => {
    if (!string.match(/^[a-zA-Z0-9]+$/)) return 'Invalid Username';
    if (isLongEnough(string, 3)) return isLongEnough(string, 3);
}


export {
    isLongEnough,
    hasNumber,
    hasCapitalLetter,
    isValidPassword,
    isValidEmail,
    isValidUsername,
    matchesPassword,
    validateLocationInput,
    isNotEmpty };
