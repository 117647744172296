import React from 'react';
import './ErrorFallback.css'; // Import your CSS file

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div className="error-fallback-container">
      <h2 className="error-title">Oops! Something went wrong.</h2>
      <p className="error-message">{error.message}</p>
      <button
        onClick={resetErrorBoundary}
        className="error-button"
      >
        Try Again
      </button>
    </div>
  );
};

export default ErrorFallback;
