export const locationsList = [
  // The Winchester Mystery House: A mansion with numerous unexplained oddities, built by Sarah Winchester.
  { latitude: 37.3184, longitude: -121.9511 }, // Winchester Mystery House, San Jose, California

  // The Villisca Axe Murder House: Site of a gruesome unsolved 1912 murder, where eight people were killed.
  { latitude: 40.9308, longitude: -94.9783 }, // Villisca Axe Murder House, Villisca, Iowa

  // The Greenbrier Bunker: A massive underground bunker designed to house the United States Congress in the event of a nuclear war.
  { latitude: 37.784, longitude: -80.3036 }, // The Greenbrier Bunker, White Sulphur Springs, West Virginia

  // Centralia: A nearly abandoned town due to an underground coal fire burning since 1962.
  { latitude: 40.8043, longitude: -76.3408 }, // Centralia, Pennsylvania

  // Skinwalker Ranch: A site of extensive paranormal and UFO-related phenomena.
  { latitude: 40.2554, longitude: -109.889 }, // Skinwalker Ranch, Utah

  // The Cecil Hotel: Known for numerous deaths, suicides, and its connection to serial killers.
  { latitude: 34.0449, longitude: -118.2509 }, // The Cecil Hotel, Los Angeles, California

  // The Gates of Hell: An urban legend, it's a series of drainage tunnels with purported paranormal activity.
  { latitude: 40.8575, longitude: -74.1283 }, // The Gates of Hell, Clifton, New Jersey

  // The Amityville Horror House: Site of the DeFeo family murder and the basis for the Amityville horror stories.
  { latitude: 40.6668, longitude: -73.4148 }, // The Amityville Horror House, Amityville, New York

  // Donner Party Memorial: Site where the Donner Party was trapped during the winter of 1846-47, leading to cannibalism.
  { latitude: 39.3173, longitude: -120.3293 }, // Donner Party Memorial, Truckee, California

  { latitude: 30.836612850307073, longitude: -89.5302906394696 }, // Eclectic Cafe, Poplarville, MS
];

export const getRandomPlace = () => {
  const randomIndex = Math.floor(Math.random() * locationsList.length);
  return locationsList[randomIndex];
};
