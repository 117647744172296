import React, { useMemo, useState } from 'react';
import './CustomInput.css';

const CustomInput = ({ label, id, value, onChange, placeholder, type, validators, disabled, style, inputStyle }) => {
  const [isInvalid, setIsInvalid] = useState('');

  useMemo(() => {
    if (!value) return;
    // Skip validation if validators array is invalid
    if (Array.isArray(validators)) {
      // Loop through all validator functions with a breakable loop to allow updating the error message
      for (let index = 0; index < validators.length; index++) {
        const validator = validators[index]; // Get validator by index
        if (validator(value)) { // Verify data against validator
          setIsInvalid(validator(value)); // If invalid, update error message
          return; // Escape from loop
        }
        setIsInvalid(''); // Reset error message if no errors found
      }
    }
  }, [validators, value]); // Will be run only when "validators" or "value" changes (e.g., when the user types)

  return (
    <div className='input-field-wrapper' style={style}>
      <label className='input-label' htmlFor={id}>{label}</label>
      <input
        type={type || "text"}
        id={id}
        placeholder={placeholder}
        className='input-field'
        value={value}
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
        style={inputStyle || {}}
      />
      <div className='input-error-wrapper'>{isInvalid}</div>
    </div>
  );
}

export default CustomInput;
