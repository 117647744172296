import React, { useState, useRef, useEffect } from 'react';
import './CustomDropdown.css';

const CustomDropdown = ({ options = [],label = '', selectedValues, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    onChange((prevSelected) => {
      if (prevSelected.includes(option)) {
        return prevSelected.filter((item) => item !== option);
      }
      return [...prevSelected, option];
    });
  };

  return (
    <div ref={wrapperRef} className="CustomDropdown-wrapper">
      {label && <label className="CustomDropdown-label">{label}</label>}
      <div className={`CustomDropdown-select ${isOpen ? 'CustomDropdown-select-isOpened' : '' }`} onClick={toggleDropdown}>
        {`${selectedValues.length} items selected` || 'Select an option'}
      </div>
      {isOpen && (
        <div className="CustomDropdown-options">
          {options.map((option, index) => (
            <div
              key={index}
              className="CustomDropdown-option"
              onClick={() => handleOptionClick(option)}
            >
                <input
                    type="checkbox"
                    checked={selectedValues.includes(option)}
                    readOnly
                />

              <div>{option}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
