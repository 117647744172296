export const logErrorToServer = ({ error, info }) => {
  const deviceInfo = {
    userAgent: navigator.userAgent,
    language: navigator.language,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  };
  if (navigator.userAgentData) {
    navigator.userAgentData.getHighEntropyValues(["platform"]).then((ua) => {
        deviceInfo.userAgentInfo = ua; // This will give you the platform information
    });
  }
  const url = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_API_WEBSOCKET_WSS : process.env.REACT_APP_API_WEBSOCKET_WSS;

  const websocket = new WebSocket(url);
  websocket.addEventListener("open", (event) => {
    websocket.send(
      JSON.stringify({
        action: "reportError",
        errorReport: { message: error.message, info, deviceInfo },
      })
    );
  });
};
