import React from 'react';
import { CgDollar } from 'react-icons/cg';
import './PriceLevel.css';

    const PriceLevel = ({ priceLevel }) => {
        if (!priceLevel) return null;
        return (
            <div className="price-level">
            {[...Array(priceLevel)].map((_, i) => (
                <CgDollar key={i} className='PriceLevel-dollar-icon' />
            ))}
            </div>
        );
    };

export default PriceLevel;
