import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CgMinimize, CgMaximize } from "react-icons/cg";
import ModalPopup from '../ModalPopup/ModalPopup';
import './FullscreenToggleButton.css';

const FullscreenToggleButton = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Check for mobile devices only
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement != null);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    localStorage.removeItem("fullscreenPreference"); //reset preference for next visit
    if (!document.fullscreenEnabled) {
      // Open the modal if fullscreen is not supported
      setIsModalOpen(true);
      return;
    }

    if (!isFullscreen && document.fullscreenElement === null) {
      // Enter fullscreen mode
      document.documentElement.requestFullscreen().catch(console.error);
    } else {
      // Exit fullscreen mode
      document.exitFullscreen().catch(console.error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={toggleFullscreen}
        aria-label={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
      >
        {document.fullscreenElement != null ? (
          <CgMinimize className="FullscreenToggleButton-iconButton" />
        ) : (
          <CgMaximize className="FullscreenToggleButton-iconButton" />
        )}
      </IconButton>

      {/* ModalPopup for unsupported fullscreen */}
      <ModalPopup isOpen={isModalOpen} onClose={closeModal} allowExit={true}>
        <div className="FullscreenToggleButton-modal-content" style={{ textAlign: "center" }}>
          <h2>Fullscreen Not Supported</h2>
          <p>Your browser does not support fullscreen mode. For the best experience, consider downloading Chrome.</p>
          <a
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noopener noreferrer"
            className="FullscreenToggleButton-download-link"
          >
            Download Chrome
          </a>
        </div>
      </ModalPopup>
    </>
  );
};

export default FullscreenToggleButton;
